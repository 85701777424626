.project-page {
    display: flex;
    width: 100vw;
    margin-top: 60px;
    flex-direction: row;
    justify-content: center;
    /* align-items: center; */
}

.side-decor {
    width: 4%;
    justify-content: flex-end;
    align-items: flex-end;
}



.line-streak {
    position: fixed;
    left: calc(4% + 2.5px);
    top: 60px;
    width: 1px;
    background: rgba(255, 255, 255, 0.3); 
    min-height: 100vh;
    z-index: -1;
} 

.arrow {
    width: 0;
    height: 0;
    position: fixed;
    left: calc(4% - 2.5px);
    top: 60px;
    border-left: 5px solid transparent; /* Left half of the arrow */
    border-right: 5px solid transparent; /* Right half of the arrow */
    border-top: 10px solid rgb(135, 132, 132); /* Color of the arrow */
    z-index: 2;
  }

.gradient-streak {
    position: fixed;
    left: 4%;
    top: 60px;
    width: 5px; /* Adjust as needed */
    background: linear-gradient(to bottom, rgba(1, 26, 188, 0.5), #007bff); /* Change to your desired gradient */
    transition: height 0.3s ease; /* Smooth transition for height change */
    z-index: 1;
  }

.projects {
    width: 90%;
    padding: 2vh 5vw 5vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 50px;
}

.project-page-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
}
.proj-page-title {
    margin: 0;
}

.proj-page-description {
    margin: 0;
}

.project-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    gap: 20px;
    padding: 50px;
    position: relative;
}

.project-container:before, .project-container>:first-child:before {
    position:absolute;
    width:18vw; height: 15vh;
    border-color: white;
    border-style: solid;
    content: ' ';
  }
  .project-container:before {top:0;left:0;border-width: 5px 0 0 5px}
  .project-container>:first-child:before {bottom:0;right:0;border-width: 0 5px 5px 0}

.project-info {
    width:50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start; 
    justify-content: flex-start;
    gap: 30px;
}

.title-text {
    margin: 0;
}

.proj-description {
    margin: 0;
}

.project-links {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    gap: 10px;
}

.site-link{
    background: transparent;
    border: 2px solid white;
    border-radius: 10px;
    padding: 10px 20px;
    color: white;
    font-size: 16px;
    cursor: pointer;
    text-decoration: none;
    width: 50%;
    transition: background 0.3s ease, color 0.3s ease;
    display: flex;
    justify-content: center;
}

/* Hover Effect */
.site-link:hover {
    background: #007bff; 
    color: white;
    border-color: #007bff;
}

img {
    width: 50%;
}


.connect-cover {
    position: relative;
    width: 4%;
}
.connect {
    position: fixed;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
    width: 75px;
    padding-bottom: 20px;
    /* background-color: blue; */
}

.social-logo {
    width: 100%;
    justify-content: center;
}

@media (max-width: 980px) {

    .projects {
        width: 80%;
        gap: 20px;
    }

    .line-streak {
        left: calc(2% + 2.5px);
    } 
    
    .arrow {
        left: calc(2% - 2.5px);
      }
    
    .gradient-streak {
        left: 2%;
      }

    .project-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
        padding: 20px;
        position: relative;
    }

    .project-info {
        width:100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start; 
        gap: 20px;
    }
    
    .title-text {
        margin: 0;
    }
    
    .proj-description {
        margin: 0;
        align-items: center;
        justify-content: center;
    }
    
    .project-links {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 90%;
        gap: 10px;
    }
    
    .site-link{
        background: transparent;
        border: 2px solid white;
        border-radius: 10px;
        padding: 10px 20px;
        color: white;
        font-size: 16px;
        cursor: pointer;
        text-decoration: none;
        width: 100%;
        transition: background 0.3s ease, color 0.3s ease;
        display: flex;
        justify-content: center;
    }

    img {
        width: 100%;
    }

    .social-logo {
        width: 100%;
    }
    .connect {
        width: 30px;
        padding-right: 10px;
    }
    
}







